<template>
  <div class="membership-card">
    <div class="d-flex justify-space-between flex-column">
      <h1 class="membership-title font-semibold text-blue py-5 line-clamp-1">
        {{ membership.name }}
      </h1>
      <div class="pa-5 membership-description" v-html="membership.description">
      </div>
    </div>
    <div class="d-flex justify-center mt-2">
      <v-btn
          class="white--text teal-color rounded-2"
          height="40"
          text
          @click="packagesModal = true"
      >
        See Packages
      </v-btn>
    </div>
    <MembershipPackagesModal
        :key="membership.id"
        :membership="membership"
        :packages-modal="packagesModal"
        @close="()=>packagesModal = false"
    />
  </div>
</template>

<script>
import MembershipPackagesModal from "@/components/Shop/Memberships/MembershipPackagesModal.vue";

export default {
  name: "MembershipCard",
  components: {MembershipPackagesModal},
  props: {
    membership: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      packagesModal: false
    }
  }
}
</script>

<style lang="scss">
.membership-card {
  padding-bottom: 1rem;
  border-radius: 1rem;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border: 0.5px solid #112A46;

  .membership-title {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 600;
    text-align: center;
    border-bottom: 0.5px solid rgba(17, 42, 70, 0.3);
  }
}

.membership-description {
  font-size: 0.875rem !important;
  height: 15rem;
  overflow-y: auto;

  p {
    margin-bottom: 0;
  }

  ul {
    padding-left: 0;

    li {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 1.25rem;
      padding-left: unset;

      &::before {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTggMTUuMTY3QTcuMTczIDcuMTczIDAgMCAxIC44MzMgOCA3LjE3MyA3LjE3MyAwIDAgMSA4IC44MzMgNy4xNzMgNy4xNzMgMCAwIDEgMTUuMTY3IDggNy4xNzMgNy4xNzMgMCAwIDEgOCAxNS4xNjdaTTggMS44MzNBNi4xNzQgNi4xNzQgMCAwIDAgMS44MzMgOGMwIDMuNCAyLjc2NyA2LjE2NyA2LjE2NyA2LjE2N1MxNC4xNjcgMTEuNCAxNC4xNjcgOCAxMS40IDEuODMzIDggMS44MzNaIiBmaWxsPSIjMDAwIiBmaWxsLW9wYWNpdHk9Ii42Ii8+PHBhdGggZD0iTTcuMDUzIDEwLjM4N2EuNS41IDAgMCAxLS4zNTMtLjE0N0w0LjgxMyA4LjM1M2EuNTAzLjUwMyAwIDAgMSAwLS43MDYuNTAzLjUwMyAwIDAgMSAuNzA3IDBMNy4wNTMgOS4xOGwzLjQyNy0zLjQyN2EuNTAzLjUwMyAwIDAgMSAuNzA3IDAgLjUwMy41MDMgMCAwIDEgMCAuNzA3bC0zLjc4IDMuNzhhLjUuNSAwIDAgMS0uMzU0LjE0N1oiIGZpbGw9IiMwMDAiIGZpbGwtb3BhY2l0eT0iLjYiLz48L3N2Zz4=);
        content: "";
        height: 16px;
        position: relative;
        width: 16px;
        min-width: 16px;
        margin-right: 0.5rem;
      }
    }
  }
}
</style>