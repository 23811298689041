<template>
  <v-container fluid>
    <v-row>
      <v-col cols="9">
        <ShopTabs/>
        <h3 class="font-semibold text-blue mb-3">
          Available Memberships
        </h3>
        <v-row>
          <v-col v-for="membership in memberships" :key="membership.id" lg="4" md="4" sm="12">
            <MembershipCard :membership="membership"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ShopTabs from "@/views/Shop/ShopTabs.vue";
import MembershipCard from "@/components/Shop/Memberships/MembershipCard.vue";

export default {
  name: "ShopMemberships",
  components: { MembershipCard, ShopTabs},
  mounted() {
    this.getAllMemberships();
  },
  watch: {
    reloadShopModule(val){
      if(val && val === 'membership'){
        this.getAllMemberships();  
      }
    }
  },  
  computed: {
    reloadShopModule(){
      return this.$store.getters.getReloadShopModule;
    },
    getCurrentVenueId(){
      return this.$store.getters.svid;
    }
  },
  methods: {
    formatDuration(duration) {
      let parts = [];

      if (duration.year) {
        parts.push(duration.year + (duration.year === 1 ? ' Year' : ' Years'));
      }

      if (duration.month) {
        parts.push(duration.month + (duration.month === 1 ? ' Month' : ' Months'));
      }

      if (duration.day) {
        parts.push(duration.day + (duration.day === 1 ? ' Day' : ' Days'));
      }

      return parts.join(', ');
    },
    getDetails(pkg) {
      let price = pkg.pricing.length ? pkg.pricing[0].total_price : 0;
      let name = pkg.pricing.length ? pkg.pricing[0].name : 'N/A';
      return [name, price];
    },
    getAllMemberships() {
      this.memberships = [];
      const venueId = this.getCurrentVenueId;
      this.showLoader('Loading Memberships');
      this.$http
          .get(`venues/shop/memberships?venueId=${venueId}`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.memberships = response.data.data;

              this.memberships.forEach((membership, index) => {
                let newObject = {};
                newObject.venue_id = membership.venue_id?membership.venue_id:venueId;
                newObject.id = membership.id;
                newObject.name = membership.name;
                newObject.description = membership.description;

                let newPackages = [];

                membership.durations.forEach((item) => {
                  item.packages.forEach((pkg) => {
                    let innerPackage = {};
                    [innerPackage.name, innerPackage.price] = this.getDetails(pkg);
                    innerPackage.duration = this.formatDuration(item);
                    innerPackage.id = pkg.id;

                    let newPricing = [];
                    pkg.pricing.forEach(plan => {
                      let innerPlan = {};
                      innerPlan.name = plan.name;
                      innerPlan.id = plan.id;
                      innerPlan.product_id = plan.product_id;
                      innerPlan.product_type_id = plan.product_type_id;
                      innerPlan.price = plan.price;
                      innerPlan.total_amount = plan.total_amount;
                      innerPlan.tax = plan.tax_amount;
                      innerPlan.total_price = plan.total_price;
                      innerPlan.divisor = plan.installment.divisor;
                      innerPlan.member_limit = pkg.members_limit
                      newPricing.push(innerPlan);
                    })

                    innerPackage.plans = newPricing;

                    newPackages.push(innerPackage);
                  })
                })

                newObject.packages = newPackages;

                this.memberships[index] = newObject;

              });
              this.$forceUpdate();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          })
          .finally(() => {
            this.hideLoader();
          });
    }
  },
  data() {
    return {
      memberships: [
        // {
        //   id: 1,
        //   name: "1 Month",
        //   description: "<ul><li><p>25% off on all Events</p></li><li><p>25% off on all Academy</p></li><li><p>25% off on all Trainers</p></li><li><p>25% off on all Facility bookings</p></li></ul><p></p>",
        //   packages: [
        //     {
        //       name: "1 Month",
        //       price: 40,
        //       duration: "1 Year",
        //       plans: [
        //         {
        //           name: '1 Payment',
        //           divisor: 1,
        //           price: 40,
        //         },
        //         {
        //           name: '2 Payment',
        //           divisor: 2,
        //           price: 40,
        //         },
        //       ]
        //     },
        //     {
        //       name: "3 Months",
        //       price: 100,
        //       duration: "1 Year",
        //       plans: [
        //         {
        //           name: '1 Payment',
        //           divisor: 1,
        //           price: 100
        //         },
        //         {
        //           name: '2 Payment',
        //           divisor: 2,
        //           price: 120
        //         },
        //       ]
        //     },
        //     {
        //       name: "6 Months",
        //       price: 200,
        //       duration: "1 Year",
        //       plans: [
        //         {
        //           name: '1 Payment',
        //           divisor: 1,
        //           price: 200
        //         },
        //         {
        //           name: '2 Payment',
        //           divisor: 2,
        //           price: 220
        //         },
        //       ]
        //     }
        //   ]
        // }
      ]
    }
  }
}
</script>

<style scoped>

</style>